import * as React from 'react'
import { Link } from 'gatsby'

// Create URL path for numeric pagination
const getPageNumberPath = (currentIndex) => {
  if (currentIndex === 0) {
    return '/'
  }
  return '/' + (currentIndex)
}

export const Pagination = ({ pageInfo, path }) => {
  if (!pageInfo) return null
  const { currentPage, pageCount } = pageInfo

  return (
    <div className="pagination">
      {Array.from({ length: pageCount }, (_, i) => {
        let numClass = 'pageNumber'
        if (currentPage === i + 1) {
          numClass = 'currentPage'
        }
        return (
          <Link to={(path?path : "") + getPageNumberPath(i)} className={numClass} key={i + 1}>
            {i + 1}
          </Link>
        )
      })}
    </div>
  )
}