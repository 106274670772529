import React from 'react';
import { Link } from 'gatsby';
import { doc } from 'prettier';
export default function BlogListingCard(props) {
	const docs = props.data
	return (<Link style={{ textDecoration: 'none' }} to={"/blogs/post/" + props.uid}>
		<div className='mb-4 '>
			<div className='blog-post'>
				<img  src={docs.banner_image} />
			</div>
			<div>
				<p className='mb-0'>{docs.date}</p>
				<h2 className='mb-2'>
					{docs.blog_title}
				</h2>
				<p className='line-preview mb-2'>
					{docs.description}
				</p>
				<p>
					<u>Read More</u>
				</p>
			</div>
		</div>
	</Link>);
}
