import * as React from 'react'
import { graphql } from 'gatsby'
import { Layout } from '../components/Layout'
import { Seo } from '../components/Seo'
import { Container, Row, Col } from 'react-bootstrap'
import { Pagination } from '../components/Pagination'
import BlogListingCard from '../components/BlogListingCard'
const BlogListing = ({ pageContext, data }) => {
    if (!data) return null
    const docs = data.allPrismicBlogPost
    return (
        <Layout isHomepage>
            <Seo title="Blogs" />
            <Container>
                <Row style={{ marginTop: '4em', minHeight: '80vh' }}>
                    <Col md={9}>
                        <h1>Blogs</h1>
                        <div className='mt-4'>
                            {docs.nodes.map(p => {
                                return (<BlogListingCard {...p} />)
                            })}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={9}>
                        <Pagination path="/blogs" pageInfo={docs.pageInfo} />
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}

export const query = graphql`
query GetBogLists($limit: Int!, $skip: Int!) {
    allPrismicBlogPost(
        limit: $limit
        skip: $skip
    ) {
      nodes {
        uid
        data {
          banner_image
          blog_title
          description
          date
        }
      }
      pageInfo {
        currentPage
        pageCount
      }
    }
  }
  
`

export default BlogListing